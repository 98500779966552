@import '../../../../../assets/css/partials/mixin-definition';

.careers-header {
    visibility: visible !important;
    display: flex;
    width: 100%;
    height: 72px;
    /* IE HACK: Needs flex-direction: column even though there's only 1 child */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & .amp-hamburger {
      cursor: pointer;
      & .amp-hamburger-icon {
        width: 70%;
        & .amp-hamburger-icon-dash {
          height: 3px;
        }
      }
    }
    & .careers-siteLogo {
      @include sm-md-viewport {
        width: 125px;
      }
      @include lg-viewport {
        width: 150px;
        padding-left: 10px;
        padding-right: 10px;
      }
      @media (min-width: 1200px) {
        padding-left: 0;
        padding-right: 0;
      }
      @media (max-width: 1024px) {
        width: 125px;
      }
      @media (min-width: 1025px) {
        width: 150px;
        padding-left: 10px;
        padding-right: 10px;
      }
      & img {
        width: 100%;
      }
    }
    & .Link--trigger::after {
      top: 0px !important;
    }

    & .utilityNav {
      width: auto;
      height: 50px;
      & .icon-globe {
        text-decoration: none;
      }

      & .country-menu {
        display: none;
        z-index: 16;
        & a {
          text-decoration: none;
        }
      }
      & .country-menu.is-visible {
        display: block;
      }
    }
  
      & .utils-links {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .Tooltip-target {
        border-bottom: 0
      }

      & .tooltip-language {
        display: none;
        position: absolute;
        width: 240px;
        top: 60px;
        & .Tooltip-content {
          width: 240px;
          & ul {
            margin-left: 10px;
            & li {
              list-style-type: none;
              &:first-child {
                padding-bottom: 30px;
              }
            }
          }
        }
      }
  }
  