@import '../../../../../assets/css/partials/mixin-definition';

:host {
  background: #FFF;
  display: block;
  height: auto;
  width: 100%;
}
  
/* Navigation variables */
:root {
  --color-neutral-master: rgba(47, 48, 51, 1);
  --color-hover: #DDEAF5;
}
:host {
  background: #FFF;
  display: block;
  height: auto;
  width: 100%;
}

/* Navigation variables */
:root {
  --color-neutral-master: rgba(47, 48, 51, 1);
  --color-hover: #DDEAF5;
}

.careers-nav,
.careers-breadcrumb {
  display: flex;
  width: 100%;
  /* IE HACK: Needs flex-direction: column even though there's only 1 child */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: 14px !important; */
}

.careers-nav {
  z-index: 101;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(247,247,247,1) 100%);
}

.careers-breadcrumb {
  position: absolute;
  z-index: 15;
}

.careers-nav.sticky {
  position: fixed;
  top: 0;
}

.careers-nav .careers-mainNav,
.careers-breadcrumb .careers-mainBreadcrumb {
  flex: 1 1 auto;
}

/* Primary Navigation */
.careers-nav nav,
.careers-breadcrumb nav {
  position: relative;
  width: 100%;
}

.careers-nav .careers-mainNav-link:hover,
.careers-nav .careers-childNav-link:hover,
.careers-nav .careers-grandchildNav-link:hover {
  text-decoration: none;
}

.careers-nav .careers-mainNav-item--current::after {
  left: 0 !important;                  /* !important confirmed necessary */
  right: 0 !important;                 /* !important confirmed necessary */
}

/* Level One Dropdown Navigation */
.careers-nav .careers-childNav,
.careers-nav .careers-grandchildNav {
  position: absolute !important;                                    /* !important confirmed necessary */
  z-index: 10000 !important;                                        /* !important confirmed necessary */
  border: 1px solid rgba(47,48,51,.15) !important;                /* !important confirmed necessary */
  box-shadow: 1px 3px 5px 0 rgba(47,48,51,0.30) !important;       /* !important confirmed necessary */
}

.careers-nav .careers-childNav {
  min-width: 335px !important;         /* !important confirmed necessary */
  left: 0;
  top: 100%;
  display: none;
}

.careers-nav .careers-mainNav-item,
.careers-nav .careers-childNav-item,
.careers-nav .careers-grandchildNav-item {
  position: relative !important;       /* !important confirmed necessary */
  padding: 0 !important;               /* !important confirmed necessary */
}

.careers-nav .careers-mainNav-item,
.careers-nav .careers-childNav-item,
.careers-nav .careers-grandchildNav-item,
.careers-breadcrumb .careers-mainBreadcrumb-item {
  border-left: 0 !important;           /* !important confirmed necessary */
}

.careers-breadcrumb .careers-mainBreadcrumb-item {
  padding-left: 0 !important;           /* !important confirmed necessary */
  padding-right: 5px !important;           /* !important confirmed necessary */
}

.careers-breadcrumb .careers-mainBreadcrumb-item:last-child a {
  text-decoration: none !important;           /* !important confirmed necessary */
}

.careers-breadcrumb .careers-mainBreadcrumb-link::after:hover {
  text-decoration: none !important;
}

.careers-breadcrumb .careers-mainBreadcrumb-link-reverse {
  color: #ddd !important;
}

.careers-breadcrumb .careers-mainBreadcrumb-link-wrapper .careers-mainBreadcrumb-arrow-mobile {
  transform: translateY(1px);
  display: inline-block;
}

.careers-nav .careers-childNav-link--hasDropdown {
  position: relative;
  padding-right: 30px;
}

.careers-nav .careers-childNav-link--hasDropdown::after {
  position: absolute;
  left: calc(100% - 30px);
  top: 30%;
}

/* Level Two Dropdown Navigation */
.careers-nav .careers-grandchildNav {
  display: none;
  left: calc(100% - 1px) !important;   /* !important confirmed necessary */
  top: -11px !important;               /* !important confirmed necessary */
  white-space: nowrap;
}

/* IE HACK: Cannot combine the next two styles because IE doesn't recognize "focus-within" and so disregards the whole thing */
.careers-nav .careers-mainNav-item:hover > .careers-childNav,
.careers-nav .careers-childNav-item:hover > .careers-grandchildNav  {
  display: block;
}

.careers-nav .careers-mainNav-item:focus-within > .careers-childNav,
.careers-nav .careers-childNav-item:focus-within > .careers-grandchildNav  {
  display: block;
}

.careers-nav .careers-mainNav-link,
.careers-nav .careers-childNav-link,
.careers-nav .careers-grandchildNav-link {
  cursor: pointer !important;
  display: block;
  font-size: 14px ;
  text-decoration: none !important;
}

.careers-nav .careers-mainNav-link:hover,
.careers-nav .careers-childNav-link:hover,
.careers-nav .careers-grandchildNav-link:hover {
  cursor: pointer !important;
}

/* IE HACK: Cannot combine the next two styles because IE doesn't recognize "focus-within" and so disregards the whole thing */
.careers-nav .careers-mainNav-item:hover,
.careers-nav .careers-childNav-item:hover,
.careers-nav .careers-grandchildNav-item:hover {
  background-color: var(--color-hover) !important;         /* !important confirmed necessary */
  cursor: pointer !important;
}

.careers-nav .careers-mainNav-item:focus-within,
.careers-nav .careers-childNav-item:focus-within,
.careers-nav .careers-grandchildNav-item:focus-within {
  background-color: var(--color-hover) !important;         /* !important confirmed necessary */
}

.careers-nav .careers-searchDropdown {
  display: none;
}

.careers-nav .careers-nav-search .careers-searchDropdown {
  display: block;
}

.careers-nav .careers-searchDropdown {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 1000;
  padding: 25px 0;
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  background: -moz-linear-gradient(top,#d8d8d8 0,#fff 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#d8d8d8),color-stop(100%,#fff));
  background: -webkit-linear-gradient(top,#d8d8d8 0,#fff 100%);
  background: -o-linear-gradient(top,#d8d8d8 0,#fff 100%);
  background: -ms-linear-gradient(top,#d8d8d8 0,#fff 100%);
  background: linear-gradient(to bottom,#d8d8d8 0,#fff 100%);
}

.careers-nav .careers-searchWrapper {
  display: flex;
  justify-content: center;
}

.careers-nav .careers-searchText {
  font-style: italic;
}

.careers-nav .careers-searchText,
.careers-nav .careers-searchSubmit .careers-search-icon {
  color: #CCC;
}

.careers-nav .careers-inputWrapper {
  width: 300px;
}

.careers-nav .careers-searchInput,
.careers-nav .careers-buttonWrapper {
  border-color: #CCC !important;
}

.careers-nav .careers-searchInput {
  border-radius: 5px 0 0 5px !important;
}

.careers-nav .careers-buttonWrapper {
  padding: 5px !important;
  border-radius: 0 5px 5px 0 !important;
}

.careers-nav .careers-buttonWrapper,
.careers-nav .careers-buttonWrapper span {
  cursor: pointer !important;
}

.careers-nav nav.careers-nav-level-two {
  display: none !important;
}

.careers-nav .Popover nav.careers-nav-level-two {
  display: block !important;
}

.careers-nav .Popover {
  z-index: -1 !important;                                  /* !important confirmed necessary */
  transform: translateX(-50%) translateY(0) !important;    /* !important confirmed necessary */
}

.careers-nav .Popover.is-visible {
  z-index: 17 !important;                                  /* !important confirmed necessary */
}

.careers-nav .Popover.careers-nav-level-one.is-visible {
  transform: translateX(0) !important;                     /* !important confirmed necessary */
}

.careers-nav .Popover.careers-nav-level-two {
  transform: translateX(-50%) !important;                  /* !important confirmed necessary */
}

.careers-nav .careers-nav-level-one .careers-mainNav-backButton {
  display: none !important;                                /* !important confirmed necessary */
}

.careers-nav .careers-nav-level-two .careers-mainNav-backButton {
  cursor: pointer;
}

.careers-nav .careers-mainNav-item .careers-mainNav-link::after {
  display: none;
}

.careers-nav .careers-nav-level-two .careers-mainNav-backButton .careers-mainNav-link {
  position: relative;
  padding-left: 44px;
  color: black;
}

.careers-nav .careers-nav-level-two .careers-mainNav-backButton .careers-mainNav-link::before {
  font-size: 26px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.careers-nav .careers-utilityNav {
  display: flex;
  align-items: center;
}

.careers-nav .careers-utilityNav .careers-mainNav-item-button {
  display: none;
  padding-left: 20px;
}

.careers-nav.sticky .careers-utilityNav .careers-mainNav-item-button {
  display: block;
}

.careers-nav.sticky .careers-mainNav-link-wrapper .careers-mainNav-link {
  padding: 18px 20px !important;
}

.careers-nav.sticky .careers-home .careers-mainNav-link-wrapper .careers-mainNav-link {
  padding: 12px 20px !important;
}

.careers-nav.sticky .careers-home .careers-home-link {
  display: none !important;
}

.careers-nav .careers-home .careers-home-link-img {
  display: none;
  width: 25px;
}

.careers-nav.sticky .careers-home .careers-home-link-img {
  display: block !important;
}

.careers-nav .careers-search-label-icon {
  display: none;
}

.careers-nav.sticky .careers-search-label-icon {
  display: inline;
}

.careers-nav.sticky .careers-search-label-text {
  display: none;
}

@media (max-width: 1200px) {
  .careers-mainNav-item-button-primary {
    display: none !important;
  }
}

/* Small and medium viewport (a.k.a. hamburger menu) */
@include sm-md-viewport {
  .careers-nav.u-decoBorderLight {
    border-width: 0 !important;
  }

  .careers-nav nav,
  .careers-nav nav ul {
    flex-direction: column !important;
  }
  
  .careers-nav .careers-nav-inner {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 0 !important;
    width: 200% !important;
    min-width: 200% !important;
    max-width: 200% !important;
    height: calc(100% - 72px) !important;
  }

  .careers-nav .careers-nav-inner .careers-nav-divider {
    color: var(--color-hover) !important;
    width: auto !important;
    height: 1px !important;
  }

  .careers-nav .careers-mainNav {
    flex: 0 0 auto !important;
  }

  .careers-nav .careers-utilityNav {
    display: block;
    flex: auto;
  }

  .careers-nav .careers-mainNav-item.careers-home {
    display: none !important;
  }

  /* IE HACK: Cannot combine the next two styles because IE doesn't recognize "focus-within" and so disregards the whole thing */
  .careers-nav .careers-mainNav-item:hover > .careers-childNav,
  .careers-nav .careers-childNav-item:hover > .careers-grandchildNav  {
    display: none;
  }

  .careers-nav .careers-mainNav-item:focus-within > .careers-childNav,
  .careers-nav .careers-childNav-item:focus-within > .careers-grandchildNav  {
    display: none;
  }

  .careers-nav .careers-mainNav-item--hasDropdown {
    position: relative;
  }

  .careers-nav nav.careers-nav-level-one .careers-mainNav .careers-mainNav-item:not(.careers-mainNav-backButton) .careers-mainNav-link::after {
    display: inline-block;
    position: absolute;
    left: calc(100% - 40px);
    top: 50%;
    transform: translateY(-50%);
    font-size: 26px;
  }

  .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-mainNav-item--current .careers-mainNav-link {
    padding: 15px 20px;
    background: rgba(47,48,51,.05) !important;
    position: relative;
  }

  .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav-item--current > div ~ ul,
  .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-childNav-item--current > div ~ ul {
    display: block !important;         /* !important confirmed necessary */
    position: relative !important;     /* !important confirmed necessary */
  }

  .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-mainNav-item {
    display: none !important;          /* !important confirmed necessary */
    background: none !important;       /* !important confirmed necessary */
  }

  .careers-nav .Popover .careers-mainNav-item::after,
  .careers-nav .Popover .careers-childNav-item::after {
    display: none !important;          /* !important confirmed necessary */
  }

  .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-mainNav-item--current {
    display: block !important;         /* !important confirmed necessary */
    padding: 0;
  }

  .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-grandchildNav-item .careers-grandchildNav-link-wrapper,
  .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-mainNav-item.careers-mainNav-item--hasDropdown .careers-mainNav-link-wrapper,
  .careers-nav .careers-utilityNav .careers-mainNav-item:not(.careers-search-item) {
    padding: 0 !important;             /* !important confirmed necessary */
  }

  .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-grandchildNav-link,
  .careers-nav .careers-utilityNav .careers-mainNav-item:not(.careers-search-item) .careers-mainNav-link {
    padding: 15px 20px;
  }

  .careers-nav .careers-childNav,
  .careers-nav .careers-grandchildNav {
    border: 0 solid transparent !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  .careers-nav .careers-grandchildNav {
    position: relative !important;
    top: 0 !important;
    left: 35px !important;
  }

  .careers-nav .careers-childNav-link-wrapper .careers-childNav-link {
    position: relative;
    padding-left: 44px;
  }

  .careers-nav .careers-childNav-link--hasDropdown::after {
    content: "\E5CF";
    font-size: 20px;
    position: absolute;
    left: -10px;
    top: 48%;
    transform: translateY(-50%);
    color: inherit;
    font-family: "ADEL Material Symbols",sans-serif!important;
    font-feature-settings: "liga";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-variant-ligatures: discretionary-ligatures;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    display: inline-block;
    line-height: 0;
    text-decoration: none!important;
  }

  .careers-nav .careers-childNav-item--current .careers-childNav-link--hasDropdown::after {
    content: "\E316";
  }

  /* IE HACK: Cannot combine the next two styles because IE doesn't recognize "focus-within" and so disregards the whole thing */
  .careers-nav .careers-mainNav-item:hover,
  .careers-nav .careers-childNav-item:hover,
  .careers-nav .careers-grandchildNav-item:hover {
    background-color: transparent !important;
  }

  .careers-nav .careers-mainNav-item:focus-within,
  .careers-nav .careers-childNav-item:focus-within,
  .careers-nav .careers-grandchildNav-item:focus-within {
    background-color: transparent !important;
  }

  .careers-nav .careers-searchDropdown {
    position: static;
  }

  .careers-nav .careers-nav-search .careers-mainNav-item,
  .careers-nav .careers-nav-search hr,
  .careers-nav .careers-searchText {
    display: none !important;          /* !important confirmed necessary */
  }

  .careers-nav .careers-nav-search .careers-mainNav-item.careers-mainNav-backButton,
  .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-mainNav-item.careers-mainNav-backButton {
    display: block !important;
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@include md-viewport {
    .careers-nav.u-decoBorderLight {
      border-width: 0 !important;
    }

    .careers-nav nav,
    .careers-nav nav ul {
      flex-direction: column !important;
    }

    .careers-nav .careers-nav-inner {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border-radius: 0 !important;
      width: 200% !important;
      min-width: 200% !important;
      max-width: 200% !important;
      height: calc(100% - 72px) !important;
    }

    .careers-nav .careers-nav-inner .careers-nav-divider {
      color: var(--color-hover) !important;
      width: auto !important;
      height: 1px !important;
    }

    .careers-nav .careers-mainNav {
      flex: 0 0 auto !important;
    }

    .careers-nav .careers-utilityNav {
      display: block;
      flex: auto;
    }

    .careers-nav .careers-mainNav-item.careers-home {
      display: none !important;
    }

    /* IE HACK: Cannot combine the next two styles because IE doesn't recognize "focus-within" and so disregards the whole thing */
    .careers-nav .careers-mainNav-item:hover > .careers-childNav,
    .careers-nav .careers-childNav-item:hover > .careers-grandchildNav  {
      display: none;
    }

    .careers-nav .careers-mainNav-item:focus-within > .careers-childNav,
    .careers-nav .careers-childNav-item:focus-within > .careers-grandchildNav  {
      display: none;
    }

    .careers-nav .careers-mainNav-item--hasDropdown {
      position: relative;
    }

    .careers-nav nav.careers-nav-level-one .careers-mainNav .careers-mainNav-item:not(.careers-mainNav-backButton) .careers-mainNav-link::after {
      display: inline-block;
      position: absolute;
      left: calc(100% - 40px);
      top: 50%;
      transform: translateY(-50%);
      font-size: 26px;
    }

    .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-mainNav-item--current .careers-mainNav-link {
      padding: 15px 20px;
      background: rgba(47,48,51,.05) !important;
      position: relative;
    }

    .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav-item--current > div ~ ul,
    .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-childNav-item--current > div ~ ul {
      display: block !important;         /* !important confirmed necessary */
      position: relative !important;     /* !important confirmed necessary */
    }

    .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-mainNav-item {
      display: none !important;          /* !important confirmed necessary */
      background: none !important;       /* !important confirmed necessary */
    }

    .careers-nav .Popover .careers-mainNav-item::after,
    .careers-nav .Popover .careers-childNav-item::after {
      display: none !important;          /* !important confirmed necessary */
    }

    .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-mainNav-item--current {
      display: block !important;         /* !important confirmed necessary */
      padding: 0;
    }

    .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-grandchildNav-item .careers-grandchildNav-link-wrapper,
    .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-mainNav-item.careers-mainNav-item--hasDropdown .careers-mainNav-link-wrapper,
    .careers-nav .careers-utilityNav .careers-mainNav-item:not(.careers-search-item) {
      padding: 0 !important;             /* !important confirmed necessary */
    }

    .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-grandchildNav-link,
    .careers-nav .careers-utilityNav .careers-mainNav-item:not(.careers-search-item) .careers-mainNav-link {
      padding: 15px 20px;
    }

    .careers-nav .careers-childNav,
    .careers-nav .careers-grandchildNav {
      border: 0 solid transparent !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }

    .careers-nav .careers-grandchildNav {
      position: relative !important;
      top: 0 !important;
      left: 35px !important;
    }

    .careers-nav .careers-childNav-link-wrapper .careers-childNav-link {
      position: relative;
      padding-left: 44px;
    }

    .careers-nav .careers-childNav-link--hasDropdown::after {
      content: "\E5CF";
      font-size: 20px;
      position: absolute;
      left: -10px;
      top: 48%;
      transform: translateY(-50%);
      color: inherit;
      font-family: "ADEL Material Symbols",sans-serif!important;
      font-feature-settings: "liga";
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      font-variant-ligatures: discretionary-ligatures;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      display: inline-block;
      line-height: 0;
      text-decoration: none!important;
    }

    .careers-nav .careers-childNav-item--current .careers-childNav-link--hasDropdown::after {
      content: "\E316";
    }

    /* IE HACK: Cannot combine the next two styles because IE doesn't recognize "focus-within" and so disregards the whole thing */
    .careers-nav .careers-mainNav-item:hover,
    .careers-nav .careers-childNav-item:hover,
    .careers-nav .careers-grandchildNav-item:hover {
      background-color: transparent !important;
    }

    .careers-nav .careers-mainNav-item:focus-within,
    .careers-nav .careers-childNav-item:focus-within,
    .careers-nav .careers-grandchildNav-item:focus-within {
      background-color: transparent !important;
    }

    .careers-nav .careers-searchDropdown {
      position: static;
    }

    .careers-nav .careers-nav-search .careers-mainNav-item,
    .careers-nav .careers-nav-search hr,
    .careers-nav .careers-searchText {
      display: none !important;          /* !important confirmed necessary */
    }

    .careers-nav .careers-nav-search .careers-mainNav-item.careers-mainNav-backButton,
    .careers-nav .careers-nav-level-two .careers-nav-level-two .careers-mainNav .careers-mainNav-item.careers-mainNav-backButton {
      display: block !important;
    }
}

